<template>
  <div id="topTab" class="">
    <div class="flex-container">
      <template v-if="tapList" v-for="(item, index) in tapList">
        <div
          class="topTabs flex1"
          :key="index"
          @click="thisTabClick(item.state_type, item.type)"
          :class="{ act: childTab == item.type }"
        >
          {{ item.title }}
          <span class="bar_line" v-if="childTab == item.type"></span>
          <slot :data="item"></slot>
        </div>
      </template>

      <div class="search flex-container" @click="fooSearch" v-if="searchBoo">
        <div class="search_solid"></div>
        <img src="@image/me/me_search.png" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "",

  props: {
    tapList: {},
    gorouter: {
      default: "",
      type: String,
    },
    searchBoo: {
      type: Boolean,
      default: true,
    },
    tapList: {
      required: true,
    },
    thisTab: {
      default: "all",
      type: String,
    },
  },
  created() {
    //do something after creating vue instance
    this.childTab = this.thisTab;
  },
  methods: {
    fooSearch() {
      if (this.gorouter && this.gorouter.length != 0) {
        this.$router.push({ name: this.gorouter });
      } else {
        this.$emit("popupSearch");
      }
    },
    thisTabClick(state_type, type) {
      this.childTab = type;
      this.$emit("thisTabClick", state_type);
    },
  },
  data: () => ({
    childTab: "",
  }),
};
</script>
<style lang="scss" scoped>
@import "~@css/mixin.scss";
#topTab {
  width: rem(710);
  margin: 0 auto;
  height: rem(100);
  background: #fff;
  border-radius: rem(10);
  padding: 0 rem(32);
  box-sizing: border-box;

  .flex-container {
    height: 100%;
    align-items: center;
  }
}
.bar {
  width: 100%;
  height: rem(5);
  .line {
    display: block;
    width: rem(64);
    height: rem(5);
    background: linear-gradient(90deg, #ff4747 0%, #ffe5e5 100%);
    border-radius: rem(3);
  }
}
.topTabs {
  position: relative;
}
.act {
  color: #ff4747;
}
.bar_line {
  position: absolute;
  bottom: rem(10);
  left: 0;
  right: 0;
  margin: 0 auto;
  display: block;
  width: rem(64);
  height: rem(5);
  background: linear-gradient(90deg, #ff4747 0%, #ffe5e5 100%);
  border-radius: rem(3);
}
.search {
  > div {
    width: rem(2);
    height: rem(32);
    background: #ebebeb;
  }

  img {
    width: rem(36);
    height: rem(39);
    display: block;
    margin-left: rem(24);
  }
}

.topTabs {
  font-size: rem(30);
  color: #333333;
  text-align: center;
  height: rem(110);
  line-height: rem(110);
  text-align: center;
}
.act {
  font-weight: bold;
  color: #ff4949;
}
</style>
