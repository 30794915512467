<template>
  <div v-title="'全部宝贝'">
    <top-tab
      style="margin-top: 10px"
      @popupSearch="popupSearch"
      :tapList="tapList"
      @thisTabClick="setThisTabClick"
      :thisTab="thisTab"
    >
      <template slot-scope="scope">
        <img
          class="t"
          src="@image/baby/price_1.png"
          v-if="scope.data.state_type == 'price'"
        />
        <img
          class="t"
          src="@image/baby/price_2.png"
          v-if="scope.data.state_type == 'price' && cur == 'price2'"
        />
        <img
          class="t"
          src="@image/baby/price_3.png"
          v-if="scope.data.state_type == 'price' && cur == 'price3'"
        />
      </template>
    </top-tab>

    <!-- <van-list
      error-text="请求失败，点击重新加载"
      v-model="loading"
      :finished="finished"
      finished-text="已无更多明细"
      @load="onLoad"
    > -->

    <div class="all-ul">
      <div
        class="all-li flex-container"
        v-for="item in productData"
        :key="item.goods_id"
        @click="
          $router.push({
            name: 'HomeGoodsdetail',
            query: { goods_id: item.goods_id },
          })
        "
      >
        <div class="left">
          <img
            :src="item.goodsimage_full_url"
            v-lazy="item.goodsimage_full_url"
          />
        </div>
        <div class="right">
          <div class="h_title">
            <h2>{{ item.goods_name }}</h2>
          </div>
          <p>{{ item.goods_salenum }}人付款</p>
          <div class="data flex-container">
            <span class="price"><i>￥</i>{{ item.goods_price }}</span>
            <span class="sub flex1">
              <i>￥</i>{{ item.goods_marketprice }}</span
            >
            <img src="@image/baby/allList-shop.png" />
          </div>
        </div>
      </div>
    </div>

    <oSearch :boo.sync="SearchShow"></oSearch>
  </div>
</template>

<script>
import topTab from "@/components/topTab";
import { List, Toast } from "vant";
import { post } from "@get/http";
import oSearch from "@view/home/view/goodsSearch/setup.vue";
export default {
  components: {
    vanList: List,
    topTab,
    oSearch,
  },
  created() {
    this.getGoodsList();
  },
  methods: {
    popupSearch() {
      this.SearchShow = true;
    },
    sort(val) {
      // 价格降序是2  升序 1,2   销量降序是 3  销量升序是 1
    },

    async getGoodsList(sort = 5, price = "") {
      let t = Toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      let { goods_list } = await post("/lw.Store/store_goods", {
        data: {
          page: 1, //页数
          storegc_id: 0, // 二级分类ID
          keyword: "",
          store_id: process.env.VUE_APP_STORE, //店铺ID
          sort_order: price, //  按低到高排序为1
          sort_key: sort, //  按什么排序
          //5是默认综合     价格 降序是2  升序 1,2   销量降序是 3  销量升序是 1
        },
      });
      Toast.clear();
      this.productData = goods_list;
    },

    onLoad() {},
    setThisTabClick(type) {
      if (type == "sale") {
        if (this.cur != "sale") {
          this.cur = "sale";
          this.getGoodsList(3);
          this.$nextTick(() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          });
        }
      }

      if (type == "defalut") {
        if (this.cur != "defalut") {
          this.cur = "defalut";
          this.getGoodsList(5);
          this.$nextTick(() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          });
        }
      }

      if (type == "new") {
        if (this.cur != "new") {
          this.cur = "new";
          this.getGoodsList(6);
          this.$nextTick(() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          });
        }
      }

      if (type == "price") {
        if (this.cur != "price2") {
          this.cur = "price2";
          this.getGoodsList(2, 1);
          this.$nextTick(() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          });
        } else {
          this.cur = "price3";
          this.getGoodsList(2, 2);
          this.$nextTick(() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          });
        }
      }

      // this.state_type = type;
    },
  },
  data() {
    return {
      SearchShow: false,
      cur: "defalut",
      store_id: 2,
      proSort: {
        // 升序降序判断数据
        price: 0,
        sale: 0,
      },
      productData: [], //产品数据
      loading: false,
      finished: false,
      thisTab: "defalut",
      tapList: [
        {
          title: "综合",
          type: "defalut",
          state_type: "defalut",
        },
        {
          title: "销量",
          type: "sale",
          state_type: "sale",
        },
        {
          title: "新品",
          type: "get",
          state_type: "new",
        },
        {
          title: "价格",
          type: "price",
          state_type: "price",
          class: "t1",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
::v-deep #topTab {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  box-shadow: rem(1) 0px rem(9) rem(-6) #333333;
}
.all-ul {
  margin-top: rem(160);
  padding-bottom: rem(80);
}
.t {
  position: absolute;
  width: rem(16);
  top: 0;
  bottom: 0;
  margin: auto;
  right: rem(20);
  height: rem(26);
}
.t1 {
  background: url("~@image/baby/price_1.png") no-repeat rem(150) center;
  background-size: rem(16) rem(26);
}
.t2 {
  background: url("~@image/baby/price_2.png") no-repeat rem(140) center;
  background-size: rem(16) rem(26);
}
.t3 {
  background: url("~@image/baby/price_3.png") no-repeat rem(140) center;
  background-size: rem(16) rem(26);
}

.all-li {
  width: rem(711);
  height: rem(280);
  margin: 0 auto;
  background: #fff;
  border-radius: rem(10);
  &:nth-of-type(1) {
    margin-top: rem(20);
  }
  margin-bottom: rem(20);
  &:last-of-type {
    margin-bottom: rem(60);
  }
  .left {
    img {
      display: block;
      width: rem(280);
      height: rem(280);
      border-radius: rem(10) 0 0 rem(10);
    }
  }
  .right {
    width: 100%;
    padding: rem(30);
    box-sizing: border-box;
    .h_title {
      height: rem(150);
    }
    h2 {
      font-weight: bold;
      font-size: rem(30);
      line-height: rem(40);
      color: #333333;

      @include ell2;
    }
    p {
      color: #808080;
      font-size: rem(22);
    }
    .data {
      // margin-top: rem(10);
      justify-content: center;
      align-items: baseline;
      .price {
        color: #ff4747;
        font-size: rem(36);
        font-weight: bold;
        i {
          font-size: rem(20);
        }
      }
      .sub {
        text-decoration: line-through;
        color: #b2b2b2;
        font-size: rem(24);
        margin-left: rem(20);
        i {
          font-size: rem(20);
        }
      }
      img {
        width: rem(43);
        height: rem(40);
        display: block;
      }
    }
  }
}
</style>
